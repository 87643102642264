import React from "react"

import { graphql } from "gatsby"
import Seo from "../components/Seo"
import Layout from "../components/Layout"
import MissionDescription from "../components/mission/MissionDescription"
import AboutHeader from "../components/About/AboutHeader"
import TrustUsContainer from "../components/trustUs/TrustUsContainer"

export default function About({ data }: any) {
  const { title, subtitle, header_img } = data.header.frontmatter

  const { desc_title, desc_text, desc_image } = data.mission.frontmatter

  const seo = {
    metaTitle: title,
    metaDescription: subtitle,
  }

  const header_image = data.staticImg.nodes.find((el: any) => {
    return el.name == header_img
  })?.childrenImageSharp[0]

  const mission_desc_image = data.missionImg.nodes.find((el: any) => {
    return el.name == desc_image
  })?.childrenImageSharp[0]

  let with_slider = false
  if (typeof window !== "undefined") {
    with_slider = window.screen.width <= 768
  }

  return (
    <Layout>
      <Seo seo={seo} lang={data.locales.edges[0].node.language} />
      <AboutHeader
        title={title}
        subtitle={subtitle}
        header_img={header_image}
      />
      <MissionDescription
        title={desc_title}
        text={desc_text}
        desc_image={mission_desc_image}
      />{" "}
      <div className="md:mt-24">
        <TrustUsContainer withSlider={with_slider} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    header: markdownRemark(
      frontmatter: { slug: { eq: "about/static" }, lang: { eq: $language } }
    ) {
      id
      frontmatter {
        lang
        slug
        title
        subtitle
        header_img
        video
        video_title
        video_subtitle
        video_bg_image
        twitterFeed_title
        twitterFeed_subtitle
        features {
          title
          text
          logo
        }
      }
    }
    staticImg: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        relativePath: { glob: "assets/index/*" }
      }
    ) {
      nodes {
        name
        childrenImageSharp {
          gatsbyImageData
        }
      }
    }
    mission: markdownRemark(
      frontmatter: { slug: { eq: "mission/static" }, lang: { eq: $language } }
    ) {
      id
      frontmatter {
        lang
        slug
        title
        subtitle
        desc_title
        desc_text
        desc_image
      }
    }
    missionImg: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        relativePath: { glob: "assets/teams/*" }
      }
    ) {
      nodes {
        name
        childrenImageSharp {
          gatsbyImageData
        }
      }
    }
    testimonialsImg: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        relativePath: { glob: "assets/index/testimonials/*" }
      }
    ) {
      nodes {
        name
        childrenImageSharp {
          gatsbyImageData
        }
      }
    }
    productsStatic: markdownRemark(
      frontmatter: { slug: { eq: "products/static" }, lang: { eq: $language } }
    ) {
      frontmatter {
        title
        subtitle
      }
    }
    productsList: allMarkdownRemark(
      filter: {
        frontmatter: { slug: { glob: "products/*/*" }, lang: { eq: $language } }
      }
    ) {
      nodes {
        html
        id
        frontmatter {
          slug
          name
          description
          on_homepage
          logo_light
          logo_dark
        }
      }
    }
    testimonialsList: markdownRemark(
      frontmatter: {
        lang: { eq: $language }
        slug: { eq: "index/testimonials" }
      }
    ) {
      id
      frontmatter {
        title
        subtitle
        testimonials {
          name
          position
          avatar
          text
        }
      }
    }
    logos: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)|(svg)/" }
        relativePath: { glob: "assets/logos/*" }
      }
    ) {
      nodes {
        name
        childrenImageSharp {
          gatsbyImageData
        }
        relativePath
        extension
      }
    }
  }
`
