import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"
import { Trans } from "react-i18next"

export default function AboutHeader({
  title,
  subtitle,
  header_img,
}: {
  title: string
  subtitle: string
  header_img: IGatsbyImageData
}) {
  const image = getImage(header_img)
  return (
    <header>
      <section className=" relative table w-full py-36 lg:py-44">
        {image && (
          <GatsbyImage
            className="absolute inset-0 h-full w-full object-cover"
            image={image}
            alt="team-header-bg"
          />
        )}
        <div className="absolute inset-0 bg-black opacity-75"></div>
        <div className="container">
          <div className="mt-10 grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-6 text-3xl font-extrabold uppercase leading-normal text-white md:text-4xl md:leading-normal">
              {title}
            </h3>
            <p
              className="mx-auto max-w-xl text-lg text-slate-100"
              dangerouslySetInnerHTML={{ __html: subtitle }}
            ></p>
          </div>
        </div>

        <div className="absolute bottom-5 right-0 left-0 z-10 mx-3 text-center">
          <ul className="breadcrumb breadcrumb-light mb-0 inline-block">
            <li className="breadcrumb-item inline">
              <a href="/">Digikare</a>
            </li>
            <li className="breadcrumb-item active inline" aria-current="page">
              <Trans>NAV.team</Trans>
            </li>
          </ul>
        </div>
      </section>
      <div className="relative">
        <div className="shape z-1 overflow-hidden text-white dark:text-slate-900">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
    </header>
  )
}
